/* src/components/Footer.css */

.footer {
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f8f8f8;
  padding: 20px 40px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto; 
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
}

.footer-section {
  display: flex;
  flex-direction: row; /* Изменено с 'column' на 'row' */
  align-items: center;
  margin-right: 20px; /* Добавлено для отступа между секциями */
  text-decoration: none;
}

.footer-section a {
  color: #000;
  text-decoration: none;
  margin-right: 15px; /* Добавлено для отступа между ссылками */
}

.footer-section a:last-child {
  margin-right: 0; /* Убираем отступ с последней ссылки */
}

.social-media {
  display: flex;
  align-items: center;
}

.social-media a {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
}

.phone-number {
  font-weight: bold;
  color: #000;
  margin-left: auto; /* Добавлено для выравнивания номера телефона вправо */
  
}

@media (max-width: 768px) {
  /* Стили для планшетов и смартфонов */
  .footer-content {
      flex-direction: column;
      align-items: center;
  }

  .footer-section {
      margin-right: 0;
      margin-bottom: 10px;
  }

  .social-media a {
      margin-right: 5px;
  }

  .phone-number {
      margin-left: 0;
      margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  /* Стили для смартфонов */
  .footer-section a {
      font-size: 0.8em;
  }
}
