.create-user-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;
  }
  
  .user-section {
    flex: 1;
    min-width: 300px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    padding: 20px;
    animation: fadeIn 0.5s;
    transition: transform 0.3s ease-in-out;
  }
  
  .user-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .create-user-title {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
  }
  
  .create-user-form {
    display: flex;
    flex-direction: column;
  }
  
  .input-icon {
    color: #999;
    margin-right: 10px;
  }
  
  .submit-button {
    margin-top: 10px;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  }
  
  .submit-button:hover {
    transform: translateY(-2px);
    background-color: #388e3c;
  }
  