/* DeliveryForm.css */

/* Применяемые стили для контейнера формы */
.delivery-container {
    font-family: "Arial", sans-serif;
    min-width: 90%; /* Устанавливаем максимальную ширину, чтобы соответствовать макету */
    margin: auto; /* Центрируем контейнер */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Тень для контейнера */
    border-radius: 4px; /* Слегка закругленные углы */
    padding: 20px; /* Паддинг для всего контейнера */
  }
  
  .breadcrumbs {
    padding: 0.75rem 1rem; /* Паддинг как на макете */
    font-size: 0.85rem; /* Уменьшенный размер шрифта для хлебных крошек */
    color: #6c757d; /* Цвет шрифта для хлебных крошек */
    margin-bottom: 1rem; /* Отступ снизу для разделения блоков */
  }
  
  .breadcrumbs a {
    color: #007bff; /* Цвет ссылок */
    text-decoration: none; /* Убираем подчёркивание текста */
  }
  
  .breadcrumbs a:hover {
    text-decoration: underline; /* Подчёркивание при наведении */
  }
  
  /* Отступы и рамки для блоков внутри формы */
  .address-block,
  .time-block,
  .login-block {
    background-color: #f8f9fa; /* Цвет фона блоков */
    border: 1px solid #dee2e6; /* Цвет рамки */
    min-width: 90%;
    padding: 20px; /* Внутренние отступы блоков */
    margin-bottom: 20px; /* Расстояние между блоками */
    display: flex;
  }
  
  /* Стили для заголовков внутри блоков */
  .address-block h2,
  .time-block h2,
  .login-block h2 {
    color: #ff6207e2; /* Цвет заголовков */
    margin-bottom: 20px; /* Отступ снизу для заголовков */
  }
  
  /* Стили для полей ввода */
  .address-field,
  .time-slots div,
  .login-block input {
    display: block; /* Блочное отображение */
    width: 100%; /* Полная ширина */
    padding: 0.375rem 0.75rem; /* Внутренние отступы */
    font-size: 1rem; /* Размер шрифта */
    line-height: 1.5; /* Высота строки */
    color: #495057; /* Цвет текста */
    background-color: #fff; /* Цвет фона */
    background-clip: padding-box; /* Фон только внутри поля */
    border: 1px solid #ced4da; /* Цвет рамки */
    border-radius: 0.25rem; /* Скругление углов */
    margin-bottom: 10px; /* Отступ снизу для каждого поля */
  }
  
  /* Стили для кнопок выбора времени */
  .time-slot {
    background-color: #fff; /* Цвет фона */
    border: 1px solid #ced4da; /* Цвет рамки */
    color: #495057; /* Цвет текста */
    text-align: center; /* Выравнивание текста по центру */
    padding: 0.375rem 0.75rem; /* Внутренние отступы */
    font-size: 1rem; /* Размер шрифта */
    line-height: 1.5; /* Высота строки */
    border-radius: 0.25rem; /* Скругление углов */
    cursor: pointer; /* Курсор в виде руки при наведении */
  }
  
  .summary-block {
    flex: 1;
    margin-left: 20px; /* Расстояние между блоками */
  }
  
  .payment-buttons {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 20px; /* Расстояние от блока summary к кнопкам оплаты */
  }