.cart-container {
  display: flex;
  max-width: 1200px;
  margin: 20px auto;
  flex-wrap: wrap;
}

.breadcrumbs a, .breadcrumbs span {
  color: #666;
  margin-right: 5px;
}

.breadcrumbs a {
  text-decoration: none;
}

.cart-title {
  flex-basis: 100%;
  margin: 20px 0;
  text-align: left;
}

.cart-upper-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 100%;
  margin-bottom: 20px;
}

.select-all-checkbox {
  margin-right: 10px;
}

.select-all-label {
  margin-right: 10px;
  
}

.delete-selected {
  background-color: green;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.cart-items-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Блоки товаров будут занимать все доступное пространство по ширине */
  margin-right: 20px;
}

.cart-item {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
}

.cart-item-select {
  margin-right: 10px;
}

.cart-item-image {
  width: 60px;
  height: 60px;
  background-color: #ddd;
  margin-right: 10px;
}

.cart-item-info {
  display: flex;
  flex-direction: column;
  margin-right: auto;
}

.cart-item-title, .cart-item-price {
  line-height: 1.5;
}

.cart-item-quantity-container {
  display: flex;
  align-items: center;
}

.quantity-button {
  background: none;
  border: 1px solid #ccc;
  padding: 5px;
  margin: 0 5px;
}

.quantity-number {
  margin: 0 10px;
}

.item-total-price {
  margin-left: 10px;
  font-weight: bold;
}

.cart-summary {
  flex-basis: 200px;
  border: 1px solid #ddd;
  padding: 10px;
  align-self: flex-start;
}

.summary-item, .summary-total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.checkout-button {
  width: 100%;
  background-color: orange;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.minimum-amount {
  text-align: center;
  color: #888;
  font-size: 0.9em;
  margin-top: 10px;
}
