/* src/components/Header.css */

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f8f8;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  font-weight: bold;
}

.catalog-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  position: relative; /* Для позиционирования выпадающего меню */
}

.catalog-dropdown {
  display: none; /* По умолчанию не показывать */
  position: absolute;
  left: 0;
  top: 100%;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
  z-index: 1000;
  width: 100%; /* Можно настроить по вашему усмотрению */
}

.header .catalog-btn:focus + .catalog-dropdown,
.header .catalog-btn:hover + .catalog-dropdown,
.header .catalog-dropdown:hover {
  display: block; /* Показать при наведении или фокусе */
}

.catalog-dropdown ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.catalog-dropdown li {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.catalog-dropdown li:hover {
  background-color: #f7f7f7;
}

.search-input {
  border: 1px solid #ccc;
  padding: 10px;
  width: 20%;
}

.navigation-icons {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* .favorites-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: none;
  border-radius: 5px;
  cursor: pointer;
} */

.favorites-btn:hover {
  background-color: #0056b3;
}


.user-info {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.username {
  font-weight: bold;
}

.user-dropdown {
  font-weight: bold;
}

/* Медиа-запросы для Header */
@media (max-width: 768px) {
  /* Стили для планшетов и смартфонов */
  .header {
    flex-direction: column;
    padding: 10px;
  }

  .search-input {
    width: 90%;
    margin: 10px 0;
  }

  .navigation-icons, .user-info {
    flex-direction: row;
    justify-content: center;
    margin: 10px 0;
  }

  .catalog-btn {
    padding: 5px;
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  /* Стили для смартфонов */
  .header {
    flex-direction: column;
  }

  .logo, .catalog-btn, .navigation-icons, .user-info {
    width: 100%;
    text-align: center;
  }

  .catalog-btn, .favorites, .orders, .cart, .username, .user-dropdown {
    margin: 5px 0;
  }
}
.category-dropdown {
  position: relative;
  cursor: pointer;
}

.categories-popup {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f0f0f0;
  border: 1px solid #473f3f;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 999; /* чтобы окно было поверх других элементов */
  width: 900px;
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
}

.category-item {
  padding: 5px 0;
  cursor: pointer;
}

.category-item:hover {
  background-color: #978484; /* изменяем цвет при наведении */
}