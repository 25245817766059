.contact-container {
    font-family: 'Arial', sans-serif;
    color: #333;
    padding: 20px;
    text-align: left;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex; /* Установка flex-контейнера */
    flex-direction: column; 
  }
  
  h1, h2 {
    color: #333;
  }
  
  .contact-info {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  
  .contact-block {
    display: flex;
    align-items: flex-start;
  }
  
  .contact-icon {
    margin-right: 10px;
    color: #333;
  }
  
  .contact-text {
    display: flex;
    flex-direction: column;
  }
  
  .contact-text p {
    font-size: 16px;
    margin: 0;
    margin-bottom: 4px;
  }
  
  .contact-text a {
    color: black;
    text-decoration: underline; /* Добавлено подчеркивание */
    font-size: 18px;
  }
  
  .section-title {
    margin-bottom: 40px;
    margin-top: 120px;
  }
  
  .city-button {
    width: 100px;
  }
  
  .store-list {
    margin-top: 20px;
  }
  
  .store {
    color: #333;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .store-info {
    display: flex;
    flex-direction: column; /* Изменено с row на column */
    align-items: flex-start; /* Добавлено для выравнивания элементов */
  }
  
  .store-name {
    margin-bottom: -15px; /* Уменьшено расстояние между элементами */
    margin-left: 6px;
  }
  
  .store-address,
  .store-phone {
    display: flex;
    align-items: center;
    margin-bottom: -5px; /* Уменьшено расстояние между элементами */
  }
  
  .store-icon{
    margin-right: 5px;
    color: #333;
  }
  
  .store a {
    color: black; /* Цвет ссылок номеров телефонов */
    text-decoration: underline; /* Добавлено подчеркивание */
  }
  
  .map-container {
    height: 500px;
    min-width: 100%;
  }
  