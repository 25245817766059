.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  max-width: 400px;
  width: 100%;
  overflow-y: auto;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.5em;
  transition: color 0.3s ease-in-out;
}

.modal-close-button:hover {
  color: #f00;
}

.edit-form {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
}

input[type="text"],
input[type="email"],
input[type="tel"] {
  width: calc(100% - 24px);
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-top: 4px;
}

.save-button {
  background-color: #5cb85c;
  color: #fff;
  border: none;
  padding: 8px 15px;
  margin-top: 20px;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.3s ease-in-out;
}

.save-button:hover {
  background-color: #4cae4c;
}
