.profile-container {
  border: 1px solid #eaeaea;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  max-width: 1200px;
  margin: 20px auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-header {
  margin-bottom: 20px;
  font-size: 16px;
  color: #333;
  display: flex;
  background: linear-gradient(90deg, #8da4bd, #7f91a3);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-header a {
  text-decoration: none;
  color: #fff;
  transition: color 0.3s ease;
}

.profile-header a:hover {
  color: #e0e0e0;
}

.profile-info {
  display: flex;
  flex-direction: column;
}

.profile-info h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
  font-weight: normal;
  text-shadow: none;
}

.name-icon {
  margin-right: 8px;
  vertical-align: middle;
  color: #000000;
  font-size: 1.5em;
}

.user-photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  color: #bdbdbd;
  margin-right: 20px;
  transition: transform 0.3s ease;
}

.user-photo:hover {
  transform: scale(1.1);
}

.user-details {
  flex-grow: 1;
}

.detail-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.detail-item:hover {
  background-color: #f0f0f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.detail-icon {
  margin-right: 8px;
  color: #8da4bd;
  transition: transform 0.3s ease;
}

.detail-item:hover .detail-icon {
  transform: scale(1.2);
}

.profile-NB {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.profile-NB button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #8da4bd;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.profile-NB button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.avatar-info {
  display: flex;
}
