/* Стили для основного контейнера */
.card-product {
    font-family: 'Arial', sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex; /* Установка flex-контейнера */
    flex-direction: column; 
  }
  
  /* Стили для хлебных крошек */
  .breadcrumb-container .breadcrumbs {
    font-size: 14px;
    color: #333;
    margin-bottom: 10px; /* Отступ между хлебными крошками и заголовком товара */
  }
  
  .breadcrumbs a {
    text-decoration: none; /* Убирает подчёркивание у ссылок */
    color: #333; /* Цвет текста ссылок */
  }
  
  .breadcrumbs a:hover {
    text-decoration: underline; /* Добавляет подчёркивание при наведении */
  }
  
  /* Стили для заголовка товара */
  .product-title {
    font-size: 24px; /* Размер шрифта заголовка товара */
    color: #333; /* Цвет текста заголовка товара */
    margin-bottom: 20px; /* Отступ снизу заголовка товара */
    text-align: left;
  }
  
  .product-header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
  }
  
  .product-art {
    font-size: 14px;
    color: #333;
    margin-right: 16px; /* Регулируйте это значение, чтобы установить расстояние между артикулом и кнопкой */
  }
  
  .favorite-button {
    padding: 8px 16px;
    font-size: 14px;
    background-color: #f2f2f2; /* Светло-серый фон */
    color: #333; /* Тёмный текст для контраста */
    border: 1px solid #dcdcdc; /* Граница немного темнее фона */
    cursor: pointer;
    border-radius: 4px; /* Скруглённые углы */
    outline: none; /* Убрать обводку при фокусе */
    transition: background-color 0.3s ease; /* Плавное изменение фона при наведении */
  }
  
  .favorite-button:hover {
    background-color: #eaeaea; /* Чуть темнее фон при наведении */
  }
  
  /* Стили для галереи изображений */
  .product-gallery {
    display: flex;
    padding: 20px;
  }
  
  .thumbnails {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Расстояние между миниатюрами */
    margin-right: 20px; /* Отступ между миниатюрами и большим изображением */
  }
  
  .thumbnail {
    width: 64px; /* Укажите размер миниатюр */
    height: 64px; /* Укажите размер миниатюр */
    background-color: #504a4a; /* Цвет фона для плейсхолдера */
  }
  
  .main-image {
    width: 400px; /* Укажите размер большого изображения */
    height: 350px; /* Укажите размер большого изображения */
    background-color: #504a4a; /* Цвет фона для плейсхолдера */
  }
  .card-product-details {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #fff;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
  }
  
  .product-pricing {
    margin-bottom: 20px; /* Добавляет пространство между блоками цен и свойств */
  }
  
  .original-price {
    text-decoration: line-through;
    color: #999;
    margin-bottom: 5px; /* Расстояние до текущей цены */
  }
  
  .current-price {
    font-size: 32px;
    color: #e53935; /* Цвет основной цены */
    margin-bottom: 20px; /* Расстояние до кнопки добавления в корзину */
  }
  
  .add-to-cart-btn {
    background-color: #ff5722; /* Цвет фона кнопки */
    color: white;
    border: none;
    padding: 15px 180px;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  .product-properties {
    border-top: 1px solid #eaeaea; /* Легкая линия сверху для разделения */
    padding-top: 20px;
  }
  
  .property-name {
    font-weight: bold;
  }
  
  .property-value {
    float: right; /* Выравнивание значения свойства справа */
    text-align: right;
    width: 100%;
  }
  
  /* Добавляет нижние отступы к каждой строке свойств */
  .brand-row,
  .country-row,
  .package-row {
    margin-bottom: 10px;
    display: flex;
  }
.product-main{
  display: flex;
}
.tovar-name{
  display: flex;
}
.PBAside{
  padding: 10px;
  margin-left: 20px;
}